<template>
  <div class="warp">
    <van-search
        v-model="keyWord"
        show-action
        placeholder="请输入手机号查询"
    >
      <template #action>
        <div @click="onSearch">搜索</div>
      </template>
    </van-search>
    <div>
      <div v-for="item in dataList" :key="item.id" class="order-info">
        <div class="name">{{ item.receiver }}（手机：{{ item.receiverMobile }}）<span class="fr" style="font-weight: 600;color: #000">{{ shopOrderStatus[item.status] }}</span></div>
        <div class="time">预约送达时间： {{ item.estimateTime || item.userTime }}</div>
        <div class="address">{{ item.address }}</div>
        <van-card
            v-for="goodsInfo in item.orderItems" :key="goodsInfo.orderItemId"
            style="background: #f8f7f7"
            :num="goodsInfo.prodCount"
            :price="goodsInfo.price"
            :title="goodsInfo.prodName"
            :thumb="goodsInfo.pic"
        />
        <div style="display: flex;justify-content: right;" v-if="refundStatus.indexOf(item.status) > -1">
          <van-button class="van-btn" type="danger" @click="refund(item.orderNumber)">退款</van-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderList, orderRefund } from '../../assets/api/order.js'
import { Toast, Dialog } from "vant";
import { shopOrderStatus } from "../../util/orderStatus.js";

export default {
  data() {
    return {
      keyWord: '',
      dataList: [],
      shopOrderStatus: shopOrderStatus,
      refundStatus: [3,4,5,6,10]
    }
  },
  mounted() {

  },
  methods: {
    onSearch() {
      if (!this.keyWord) {
        Toast('请输入查询手机号');
        return
      }
      const params = {
        receiverMobile: this.keyWord,
        orderySource: 1,
        current: 1,
        size: 1000,
      }
      orderList(params).then(res => {
        this.dataList = res.data.data.records
      })
    },
    refund(orderNumber) {
      Dialog.confirm({
        title: '退款',
        message:'是否确认退款到支付账户中?',
      })
          .then(() => {
            const params = {
              orderNumber: orderNumber
            }
            orderRefund(params).then((res) => {
              if (res.data.code == 200) {
                Toast('退款成功');
                this.onSearch()
              }
            })
          })
    }
  }
}
</script>

<style scoped lang="less">
::v-deep(.van-card__thumb) {
  width: 60px;
  height: 60px;
}
::v-deep(.van-card__content) {
  height: 60px;
  min-height: 60px;
}
.warp {
  padding: 15px 10px;

  ::v-deep(.van-tabs__nav) {
    margin: 0!important;
  }

  .order-info {
    width: calc(100% - 20px);
    color: #666;
    font-size: 12px;
    border: 1px solid #aaaaaa;
    border-radius: 8px;
    margin-top: 15px;
    padding: 5px 10px;
    position: relative;
    background: #f7f8fabd;

    .name {
      font-size: 16px;
    }
    .name, .time, .address {
      line-height: 18px;
    }
    .time {
      margin: 4px 0;
    }
    .van-btn {
      border: none;
      height: 24px;
      line-height: 24px;
      margin-top: 10px;
      margin-right: 20px;
      border-radius: 5px;
    }
  }
}
</style>
